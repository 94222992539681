import React from 'react';
import Typography from "@mui/material/Typography";

function InProgress() {
    return (


        <div className="container">
            <div className="footnote">
                <Typography style={{color: 'grey'}} className='typography'>This is a super early prototype, just so people can use the Point cam.
                    Please be patient, we're still building this thing
                If you have any spots that you want added to this site or just have any questions, please email
                If you want to add any spots, help with the design of the website or have any questions, please email below
                wavecheckph@proton.me
                <br />
                <br />
                <br />
                Ginawa itong prototype para magamit na yung Point cam. Paki hintay lang habang inaayos parin
                Kung may spot na gusto mong ipa-dagdag,tulong sa design nang website or may mga Katanungan, paki email sa 
                wavecheckph@proton.me</Typography>
                <br />
            </div>
        </div>


    );
}

export default InProgress;
